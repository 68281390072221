import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Related from "../sections/landing/popular.js";
import Find from '../sections/nose/nosefind.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Hero from "../sections/conditions/jowls/jowlshero.js";
import Causes from "../sections/conditions/jowls/jowlscauses.js";
import Sym from "../sections/conditions/jowls/jowlssym.js";
import AcneTreat from "../sections/conditions/jowls/jowlstreat.js";
import Testimonial from "../sections/reviews/nosereviews.js";
import Saveface from "../sections/landing/saveface";
import ReviewsD from "../sections/nose/nosedesktopreviews.js";
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"
import { Title, Section, Text, Span } from "../components/Core";


const TestTrustPage = () => ( 
  <>
 <Head title="Jowls Condition in London UK" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
    <Section>

   
    <Text>
        Test Trust
    </Text>
      <TrustpilotReviews
        language="en"
        culture="en-GB"
        theme="light"
        width="100%"
        stars="4,5"
        height="28px"
        template="5406e65db0d04a09e042d5fc"
        business="5de28a2e743213000185c0de"
        username="www.dermamina.com"
      />
  </Section>
     </PageWrapper>
 
  </>
)
export default TestTrustPage

